var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-overlay',{attrs:{"z-index":"20","value":_vm.searchFocused}},[(_vm.loading)?_c('v-progress-circular',{staticClass:"l2",attrs:{"indeterminate":"","size":"100","color":"primary"}},[_c('v-progress-circular',{staticClass:"l3",attrs:{"indeterminate":"","size":"75","color":"primary"}})],1):_vm._e()],1),_c('v-app-bar',{staticClass:"py-1 topBar",style:(`${_vm.searchFocused ? 'z-index:40' : ''}`),attrs:{"flat":"","id":"app-bar","min-height":"65px","app":"","color":`${_vm.$vuetify.theme.dark ? `#272727` : '#373737'}`}},[_c('v-overlay',{attrs:{"z-index":"30","value":_vm.searchFocused}}),(!_vm.isMobile)?_c('LoganBreadcrumbs'):_vm._e(),_c('v-spacer'),_c('v-autocomplete',{ref:"textfild",staticClass:"pt-0 mt-0",style:({
				position: _vm.isMobile ? 'unset' : 'absolute',
				left: _vm.isMobile ? '0px' : '50%',
				transform: _vm.isMobile ? 'unset' : 'translate(-60%)',
				opacity: _vm.searchFocused ? '1' : '0.4',
				'z-index': _vm.searchFocused ? '40 !important' : 'unset',
				width: _vm.searchFocused ? '30%' : 'unset',
			}),attrs:{"hide-no-data":"","hide-details":"","hide-selected":"","no-filter":"","item-color":"primary","error-messages":_vm.errors,"multiple":_vm.readify.length > 0,"dark":"","full-width":"","rounded":"","auto-select-first":"","search-input":_vm.searchKey,"height":"40","items":_vm.acItems,"loader-height":"0","placeholder":`${
				!_vm.searchFocused ? `Search (press ${_vm.isIOS ? '⌘' : 'Ctrl'} + K )` : ``
			}`},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.thisiscrazy.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.heresmynumber.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.maybe.apply(null, arguments)}],"input":_vm.callme,"update:search-input":[_vm.itshard,function($event){_vm.searchKey=$event}],"update:searchInput":function($event){_vm.searchKey=$event}},scopedSlots:_vm._u([(_vm.command)?{key:"prepend-inner",fn:function(){return [_c('v-chip',{class:{ error: _vm.errors.length > 0 },attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.command?.item?.name)+" ")])]},proxy:true}:null,{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex flex-row align-center mt-1"},[_c('v-slide-y-reverse-transition',[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"23","color":'primary'}}):_vm._e()],1),_c('v-slide-y-reverse-transition',[(!_vm.loading && _vm.errors?.length > 0)?[_c('span',{staticStyle:{"opacity":"0.5"}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.errors[0]))]),_c('v-icon',{attrs:{"size":"20","color":"error"}},[_vm._v(" mdi-exclamation ")])],1)]:_vm._e()],2),(!_vm.loading && _vm.errors?.length === 0)?_c('v-icon',{staticClass:"mb-1",style:(`${
							_vm.searchFocused
								? 'transform: rotate(180deg); color: var(--v-kajot-primary)'
								: ''
						}`)},[_vm._v(" mdi-menu-down ")]):_vm._e()],1)]},proxy:true},{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item?.item?.name.toUpperCase())+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item?.item.note.replace(`$casino$`, _vm.currentCasino))+" ")])],1),_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm._f("Capitalize")(item?.item?.type)))])],1)]}},{key:"selection",fn:function({ item }){return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item?.item?.name))]),_vm._v(" >  ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-spacer'),_c('span',{staticClass:"mr-3"},[_c('CasinoMenu')],1),_c('UserMenu')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }