var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"min-width":"250px","bottom":"","rounded":"","origin":"top right","nudge-bottom":"53"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-avatar',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],style:(`${
				_vm.$route.path.includes('profile') ? `border: 2px solid transparent` : ``
			}`),attrs:{"color":"primary"}},'v-avatar',attrs,false),on),[_c('v-img',{attrs:{"src":`https://www.gravatar.com/avatar/${_vm.userEmailHash}?d=robohash`}})],1)]}}])},[_c('v-card',{staticClass:"my-0"},[_c('v-list-item-content',{staticClass:"mx-auto justify-center align-center text-center py-2 noTouch"},[_c('div',{staticClass:"mx-auto text-center"},[_c('div',{staticClass:"my-2"},[_c('v-avatar',{staticClass:"mb-2",attrs:{"color":"primary"}},[_c('v-img',{attrs:{"src":`https://www.gravatar.com/avatar/${_vm.userEmailHash}?d=robohash`}})],1),_c('h3',[_vm._v(_vm._s(_vm.userFullName))]),_c('span',{staticClass:"text-caption mt-1"},[_vm._v(" "+_vm._s(_vm.userEmail)+" ")])],1),_c('v-divider'),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"hoverZone",on:{"click":function($event){return _vm.$router.push({
							name: 'my-profile',
							params: {
								user_id: _vm.userId,
								breadcrumbs: { title: `My profile`, hard: true },
							},
						})}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-account")]),_c('span',{staticClass:"text-button"},[_vm._v("Profile")])],1),_c('v-divider'),_c('div',{staticClass:"d-flex flex-row justify-center hoverZone",on:{"click":function($event){return _vm.darkModeChange()}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.darkModeIsOn ? 'kajot-text' : 'primary'},on:{"click":function($event){return _vm.darkModeChange(true)}}},[_vm._v(" mdi-sunglasses ")]),_c('v-switch',{staticClass:"mt-0 mx-2 py-2",attrs:{"input-value":_vm.$vuetify.theme.dark,"inset":"","ripple":false,"hide-details":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.darkModeChange()}}}),_c('v-icon',{attrs:{"color":_vm.darkModeIsOn ? 'primary' : 'kajot-text'},on:{"click":function($event){return _vm.darkModeChange(false)}}},[_vm._v(" mdi-moon-waning-crescent ")])],1),_c('v-divider'),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"hoverZone primary--text",on:{"click":_vm.refresh}},[_c('v-icon',{staticClass:"mr-3 primary--text"},[_vm._v("mdi-refresh")]),_c('span',{staticClass:"text-button"},[_vm._v("Refresh Token")])],1),(_vm.getEnvironment === 'dev')?[_c('v-divider'),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"hoverZone error--text",on:{"click":_vm.removeFooters}},[_c('v-icon',{staticClass:"mr-3 error--text"},[_vm._v("mdi-close")]),_c('span',{staticClass:"text-button"},[_vm._v("Clear storage")])],1)]:_vm._e(),_c('v-divider'),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"hoverZone error--text",on:{"click":_vm.logout}},[_c('v-icon',{staticClass:"mr-3 error--text"},[_vm._v("mdi-logout")]),_c('span',{staticClass:"text-button"},[_vm._v("Logout")])],1)],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }